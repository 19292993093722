import "../scss/style.scss";

// ---------------------------------- utils ---------------------------------

import * as utils from "./utils/utils.js";

// hamburger menu
utils.menuInit();

// show-more
utils.showmore();

// ------------------------------- components -------------------------------

// forms
import "./utils/forms";

// tabs
import "./utils/tabs.js";

// accordion
import "./utils/accordion.js";

// select
import "./utils/select.js";

// modals
import "./utils/modals.js";

// modals
import "./utils/modals.js";

// ---------------------------------- libs ----------------------------------

//swiper
import "./library/swiper.js";

//input-mask
import "./library/input-mask.js";

//nouislider
import "./library/nouislider";

// dynamic dom
import "./library/dd";

// --------------------------------------------------------------------------

import "./dev/vzmsk1.js";
import "./dev/markusDM.js";
import "./dev/ukik0.js";
import "./dev/kie6er.js";
